import React from "react"
import Layout from "../Components/layout"
import { useStaticQuery, graphql } from "gatsby"

import Hero from "../Components/ssp/hero"
import ContentCards from "../Components/ssp/contentCards"
import InquireSection from "../Components/inquirelink"
// import ServiceCards from "../Components/pageComponents/serviceCards"
import SEO from "../Components/SEO"

export default function Solutions() {

  const data = useStaticQuery(graphql`
    {
      directory: allContentJson {
        nodes {
          solutions {
            list {
              title
              image{
                src {
                  childImageSharp {
                    fixed(width: 80){
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      banner: file(relativePath: { eq: "solutions/main/solutions_banner.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)


  return (
    <Layout>
      <SEO title="Solutions" description="Fastlink is equipped with knowledgeable and experienced manpower that are certified experts to address client's request and to give service with ultimate performance and accuracy."></SEO>
      <Hero
        image={data.banner.childImageSharp.fluid}
        heading="Our Solutions"
        description="Fastlink is equipped with knowledgeable and experienced manpower that are certified experts to address client's request and to give service with ultimate performance and accuracy."
      />
      <ContentCards heading="Solutions We Offer" data={data.directory.nodes[0].solutions.list} />
      <InquireSection></InquireSection>

    </Layout>
  )
}
