import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

import { Container, Row, Col, Button } from "reactstrap"

import style from "./styles.module.css"

const ContentCards = ({ data, heading }) => {

    const list = data.map((entry, index) => {
        let slug = entry.title.toLowerCase().replace(/ /g, "-")
        return (
            <div key={index} className={style.card}>
                <div>
                    <Img fixed={entry.image.src.childImageSharp.fixed}></Img>
                </div>
                <h3 className="text-center pt-2 pb-4">{entry.title}</h3>

                <Link to={slug}>
                    <Button outline className={style.button1}>
                        Learn More
          </Button>
                </Link>
            </div>
        )
    })

    return (
        <Container id="table" fluid sm="12" className={style.grayContainer}>
            <Row className="text-center">
                <Col md="12" className="text-center">
                    <h1 className="mx-auto mb-4 heading1 p-3 pt-5">
                        <span>{heading}</span>
                    </h1>
                </Col>
            </Row>
            <div className={style.cardContainer}>{list}</div>
        </Container>
    )
}

export default ContentCards
